<template>
  <el-dialog
    append-to-body
    width="600px"
    title=""
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #title>
      <div class="el-dialog-title">{{`${isChange ? '编辑' : '创建'}`}}</div>
    </template>

    <el-form
      v-loading="loading"
      class="saft_form"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="top"
      :model="form.data"
      :rules="form.rules"
      size="small"
      hide-required-asterisk
    >
      <el-form-item prop="title" label="轮播图名称">
        <el-input
          v-model="form.data.title"
          clearable
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item prop='image' label='' class="uploadBillimg">
        <template slot="label">轮播图片</template>
        <span class="label-hint">（限1张，建议上传尺寸为686*260px的图片）</span>
        <el-upload
          class="upload-demo"
          action=""
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest"
          accept="image/jpeg, image/png, image/gif">
          <img v-if="form.data.image" :src="form.data.image" style="width:218px;height:146px;border-radius:6px;">
          <img v-else style="width:218px;height:146px;object-fit: cover;" :src="require('@/assets/img/upload3.png')" />
        </el-upload>
      </el-form-item>
      <el-form-item prop="goods_id" label="关联产品">
        <el-select v-model="form.data.company_id" filterable clearable placeholder="选择机构" @change="changeCompany" style="width:260px">
          <el-option v-for="item in companyOpt" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
        </el-select>
        <el-select v-model="form.data.goods_id" filterable clearable placeholder="选择产品" style="margin-left:10px; width:260px">
          <el-option v-for="item in GoodsOpt" :key="item.id" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop='status' label='状态'>
        <el-radio-group v-model="form.data.status">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="2">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'TagEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            title: '',
            image: '',
            company_id: '',
            goods_id: '',
            status:1,
          },
          rules: {
            title: [{required: true, message: "请输入标题", trigger: "change"}],
            image: [{required: true, message: "请上传图片", trigger: "change"}],
          }
        },
        companyOpt: [],
        GoodsOpt: [],
      }
    },

    methods: {
      // 获取详情
      getDetail(row) {
        this.getCompany();
        if(!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.form.data.company_id = row.company_id == 0 ? '' : row.company_id;
            this.form.data.goods_id = row.goods_id == 0 ? '' : row.goods_id;
            this.getGoods(row.company_id); // 根据机构id 获取产品
          })
        }
      },
      // 审核通过的机构
      getCompany() {
        this.$http.get('/admin/company/list', {params: {status:2,page:1,count:10000}}).then(res => {
          if(res.code == 1){
            this.companyOpt = res.data.list;
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.form.data.image = '';
        this.form.data.company_id = '';
        this.form.data.title = '';
        this.form.data.goods_id = '';
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let apiUrl = !this.isChange ? '/admin/banner/add' : '/admin/banner/edit'
            this.form.loading = true;
            let _params = {
              id: this.form.data.id,
              title: this.form.data.title,
              image: this.form.data.image,
              company_id: this.form.data.company_id,
              goods_id: this.form.data.goods_id,
              status: this.form.data.status,
            }
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
      changeCompany(value) {
        this.getGoods(value); // 根据机构id获取产品
        this.form.data.goods_id = '';
      },
      getGoods(value) {
        this.$http.get('/admin/goods/list', {params: {page:1,count:100000,type:1,company_id:value}}).then(res => {
          if(res.code == 1) {
            this.GoodsOpt = res.data.list;
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.loading = true;
        this.$http.post('/common/qiniu/upload', {formData, type:'upload'}).then(res => {
          this.form.data.image = res.data.all_path_url;
          this.$refs.elFormDom.clearValidate();
        }).finally(() => {
          this.loading = false;
        })
      },
    }
  }
</script>
