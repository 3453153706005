<template>
  <div class="main-view">
    <div class="section filter__wrap">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="showEdit" >创建</el-button>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:image="{row}">
        <el-image
          v-if="!!row.image"
          style="width: 60px; height: 60px"
          :src="row.image"
          fit="cover"
          :preview-src-list="[row.image]"></el-image>
          <span v-else></span>
      </template>
      <template v-slot:status="{row}">
        <el-switch
          v-model="row.status"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="2"
          @change="handleStatus(row)">
        </el-switch>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click='showEdit(row)'>编辑</el-button>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'TagIndex',
  components: {
    VTable,
    Edit,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "title", label: "轮播图名称", hidden: false },
        { name: "image", label: "图片", hidden: false },
        { name: "goods_name", label: "关联产品", hidden: false },
        { name: "company_name", label: "所属机构", hidden: false },
        { name: "status", label: " 状态", hidden: false },
        { name: "create_time", label: "创建时间", hidden: false },
        { name: "action", label: "操作", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/admin/banner/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    handleStatus(row) {
      this.$http.post('/admin/banner/operation', {id:row.id}).then(res => {
        if(res.code == 1) {
          this.$message.success("操作成功！")
          this.getTable();
        }
      })
    }
  }
}
</script>
